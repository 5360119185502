import React from 'react'
import JobsDashboard from '../SubComponents/CareersComponents/JobsDashboard'

function Careers() {
  return (
    <>
    <JobsDashboard/>
    </>
  )
}

export default Careers